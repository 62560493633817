import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import Checkbox from '@sats-group/ui-lib/react/checkbox';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import LinkButton from '@sats-group/ui-lib/react/link-button/link-button';
import Message from '@sats-group/ui-lib/react/message/message';
import Modal from '@sats-group/ui-lib/react/modal';
import Radio from '@sats-group/ui-lib/react/radio';
import Text from '@sats-group/ui-lib/react/text';
import TextArea from '@sats-group/ui-lib/react/text-area';
import TextInput from '@sats-group/ui-lib/react/text-input';

import Layout from 'shared-ui/components/layout/layout';
import PhoneInput from 'shared-ui/components/phone-input/phone-input';
import RichText from 'shared-ui/components/rich-text/rich-text';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { Lead as Props } from './lead.props';

const Lead: NamedFC<Props> = ({
  comment,
  endpoint,
  generalTerms,
  hiddenInputs = [],
  firstName,
  lastName,
  layout,
  marketingConsent,
  message,
  over18,
  personType,
  personTypeTitle,
  phone,
  skip,
  submit,
}) => {
  const [isTermsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [isMarketingConsentModalOpen, setMarketingConsentModalOpen] =
    useState<boolean>(false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    if (!(event.target as HTMLFormElement).checkValidity()) {
      event.preventDefault();
      return;
    }
  };

  return (
    <Layout {...layout}>
      <div className="lead">
        {message ? (
          <div className="lead__message">
            <Message text={message} theme={'error'} />
          </div>
        ) : null}
        <form action={endpoint} method="post" onSubmit={handleSubmit}>
          <div className="lead__form-inputs">
            {hiddenInputs.map(input => (
              <HiddenInput key={input.value} {...input} />
            ))}
            <TextInput {...firstName} />
            <TextInput {...lastName} />
            <PhoneInput {...phone} />

            <Checkbox {...over18} />

            <div className="lead__radio">
              <div className="lead__radio-title">
                <Text theme={Text.themes.emphasis} size={Text.sizes.small}>
                  {personTypeTitle}
                </Text>
                <Text
                  className="lead__radio-title-asterisk"
                  theme={Text.themes.emphasis}
                  size={Text.sizes.small}
                >
                  *
                </Text>
              </div>
              <div>
                {personType.map(radio => (
                  <Radio
                    key={radio.name}
                    data-testid={radio.value}
                    {...radio}
                  />
                ))}
              </div>
            </div>

            <TextArea {...comment} size={TextArea.sizes.small} />

            {generalTerms && generalTerms.terms ? (
              <div className="lead__terms">
                <Checkbox
                  theme={Checkbox.themes.blue}
                  {...generalTerms.checkbox}
                />
                <div>
                  <Button
                    {...generalTerms.button}
                    variant={Button.variants.tertiary}
                    size={Button.sizes.small}
                    onClick={() => setTermsModalOpen(true)}
                  />
                </div>
                {isTermsModalOpen ? (
                  <Modal
                    {...generalTerms.modal}
                    onClose={() => setTermsModalOpen(false)}
                  >
                    <RichText {...generalTerms.terms} />
                  </Modal>
                ) : null}
              </div>
            ) : null}
            {marketingConsent && marketingConsent.terms ? (
              <div className="lead__terms">
                <Checkbox
                  theme={Checkbox.themes.blue}
                  {...marketingConsent.checkbox}
                />
                <div>
                  <Button
                    {...marketingConsent.button}
                    variant={Button.variants.tertiary}
                    size={Button.sizes.small}
                    onClick={() => setMarketingConsentModalOpen(true)}
                  />
                </div>
                {isMarketingConsentModalOpen ? (
                  <Modal
                    {...marketingConsent.modal}
                    onClose={() => setMarketingConsentModalOpen(false)}
                  >
                    <RichText {...marketingConsent.terms} />
                  </Modal>
                ) : null}
              </div>
            ) : null}
            {submit ? (
              <div className="lead__submit">
                <Button
                  {...submit}
                  type="submit"
                  size={Button.sizes.large}
                  wide={true}
                />
              </div>
            ) : null}
          </div>
        </form>
        <div className="lead__skip">
          <LinkButton
            {...skip}
            variant={LinkButton.variants.secondary}
            wide={true}
          />
        </div>
      </div>
    </Layout>
  );
};

Lead.displayName = 'Lead';

export default Lead;
