import React from 'react';

import Link from '@sats-group/ui-lib/react/link';
import Text from '@sats-group/ui-lib/react/text';

import type { NamedFC } from 'shared-ui/named-fc.types';

import { MarketSelectionPage as Props } from './market-selection.props';

const MarketSelectionPage: NamedFC<Props> = ({ marketLinks, title }) => {
  return (
    <div className="market-selection-page">
      <div className="market-selection-page__title">
        <Text
          elementName="h1"
          size={Text.sizes.headline1}
          theme={Text.themes.headline}
          italic
        >
          {title}
        </Text>
      </div>
      <div className="market-selection-page__links">
        {marketLinks.map(link => (
          <div className="market-selection-page__link" key={link.href}>
            <Link {...link} size={Link.sizes.large} />
          </div>
        ))}
      </div>
    </div>
  );
};

MarketSelectionPage.displayName = 'MarketSelectionPage';

export default MarketSelectionPage;
