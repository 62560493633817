import React from 'react';

import isRunningOnClient from '@sats-group/ui-lib/react/hooks/is-running-on-client';
import LinkButton from '@sats-group/ui-lib/react/link-button/link-button';
import SvgElixia from '@sats-group/ui-lib/react/logos/elixia';
import Sats from '@sats-group/ui-lib/react/logos/sats';
import Message from '@sats-group/ui-lib/react/message/message';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'shared-ui/components/content-container/content-container';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { Confirmation as Props } from './confirmation.props';

const Confirmation: NamedFC<Props> = ({
  back,
  disclaimer,
  documentationDisclaimer,
  information,
  items,
  language,
  message,
  redirectUrl,
  title,
}) => {
  if (isRunningOnClient) {
    setTimeout(() => window.location.replace(redirectUrl), 300000);
  }

  return (
    <div className="confirmation-page">
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div className="confirmation-page__logo">
          {language === 'fi' ? <SvgElixia /> : <Sats />}
        </div>
        {message ? <Message {...message} /> : null}
        {items.length ? (
          <div className="confirmation-page__summary">
            <Text
              theme={Text.themes.headline}
              elementName="h1"
              size={Text.sizes.headline2}
              italic={true}
            >
              {title}
            </Text>
            <Text>{information}</Text>
            <Text size={Text.sizes.interface}>{disclaimer}</Text>

            <div>
              {items.map(({ title, value }) => (
                <div className="confirmation-page__line-item" key={title}>
                  <Text theme={'emphasis'}>{title}</Text>
                  <Text>{value}</Text>
                </div>
              ))}
            </div>

            <div className="confirmation-page__documentation">
              <Text theme={Text.themes.emphasis}>
                {documentationDisclaimer.title}
              </Text>
              <Text>{documentationDisclaimer.text}</Text>
            </div>
          </div>
        ) : null}
      </ContentContainer>
      <div className="confirmation-page__link">
        <LinkButton {...back} variant={LinkButton.variants.primary} />
      </div>
      <div className="confirmation-page__background"></div>
    </div>
  );
};

Confirmation.displayName = 'Confirmation';

export default Confirmation;
