import { useMask } from '@react-input/mask';
import SvgError from '@sats-group/icons/16/error';
import React, { useEffect, useRef, useState } from 'react';

import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import Select from '@sats-group/ui-lib/react/select';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import PhoneInput from 'shared-ui/components/phone-input/phone-input';
import Spinner from 'shared-ui/components/spinner/spinner';

import { get } from 'helpers/api-helper';

import { replaceQueryParameters } from 'server/query-strings';

import type { FormInputs as Props } from './form-inputs.types';

const RegistrationInput: React.FC<Props> = ({
  firstName,
  lastName,
  birthDay,
  ssn,
  gender,
  email,
  address,
  postalAreaEndpoint,
  postalAreaName,
  postalCode,
  phone,
}) => {
  const inputRef = useMask({
    mask: 'dd-mm-åååå', // NOTE: Birthday is only used in Norway.
    replacement: { d: /\d/, m: /\d/, å: /\d/ },
    showMask: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [postalArea, setPostalArea] = useState('');

  const [postalAreaErrorMessage, setPostalAreaErrorMessage] = useState('');

  const postalCodeValue = useRef('');

  // Keeps track of the debounce id between re-renders, to avoid starting multiple timers.
  const debounceTimerRef = useRef<number | null>(null);

  const handlePostalCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    const value = event.target.value;

    setPostalArea('');
    setPostalAreaErrorMessage('');

    // NOTE: We don't care about postal codes shorter than 4 digits, as Norway, Denmark, Sweden and Finland are all 4+
    if (value.length < 4) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    postalCodeValue.current = value;

    debounceTimerRef.current = window.setTimeout(() => {
      get<{ postalArea: string; errorMessage?: string }>(
        replaceQueryParameters(postalAreaEndpoint, { code: value })
      )
        .then(data => {
          if (postalCodeValue.current === value) {
            if (data.errorMessage) {
              setPostalAreaErrorMessage(data.errorMessage);
            } else {
              setPostalArea(data.postalArea);
            }
          }
        })
        .catch(() => {});
      setIsLoading(false);
    }, 3000);
  };

  // Cleans up the timer on component unmount
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  return (
    <div className="form-inputs">
      <TextInput {...firstName} />
      <TextInput {...lastName} />
      {birthDay ? <TextInput ref={inputRef} {...birthDay} /> : null}
      {ssn ? <TextInput {...ssn} /> : null}
      {gender ? <Select {...gender} /> : null}
      <PhoneInput {...phone} />
      <TextInput {...email} />
      <TextInput {...address} />
      <div className="form-inputs__postal-inputs">
        <div>
          <TextInput
            {...postalCode}
            onChange={handlePostalCodeChange}
            hasError={!!postalAreaErrorMessage}
            aria-describedby="postal-area-error"
          />
          {postalAreaErrorMessage ? (
            <Text
              className="form-inputs__postal-area-error-message"
              size={'interface'}
              id="postal-area-error"
            >
              <div>
                <SvgError />
              </div>
              {postalAreaErrorMessage}
            </Text>
          ) : null}
        </div>
        <HiddenInput value={postalArea} name={postalAreaName} />
        <div className="form-inputs__postal-area">
          {isLoading ? <Spinner size={Spinner.sizes.mini} /> : null}
          {postalArea ? <Text>{postalArea}</Text> : null}
        </div>
      </div>
    </div>
  );
};

export default RegistrationInput;
