import Crosshairs from '@sats-group/icons/24/geoposition';
import FuzzySearch from 'fuzzy-search';
import haversineDistance from 'haversine-distance';
import { by } from 'list-fns';
import React, { useMemo, useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import Expander from '@sats-group/ui-lib/react/expander';
import Search from '@sats-group/ui-lib/react/search';
import Text from '@sats-group/ui-lib/react/text';

import Layout from 'shared-ui/components/layout/layout';
import Spinner from 'shared-ui/components/spinner/spinner';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { ChooseClub as Props, SearchAreaProps } from './choose-club.props';
import Cluster from './components/cluster/cluster';

enum Mode {
  GeoLocation = 'geo-location',
  Search = 'search',
}

/** The maximum number of meters we consider to be "nearby" when checking the distance between things */
export const definitionOfNearby = 5000;

type Coordinates = {
  latitude: number;
  longitude: number;
};

const distanceBetween = (a: Coordinates, b: Coordinates) =>
  haversineDistance(a, b);

const SearchArea: React.FC<SearchAreaProps> = ({
  clubs,
  clusters,
  isGeoSearch,
  noHits,
  searchTerm,
}) => {
  if (isGeoSearch) {
    return <Cluster clubs={clubs} />;
  }

  const search = useMemo(() => {
    const searcher = new FuzzySearch(clubs, ['name', 'region'], {
      sort: true,
    });
    return (term: string) => searcher.search(term);
  }, [clusters]);

  const searchResults = useMemo(
    () => (searchTerm ? search(searchTerm).slice(0, 10) : []),
    [search, searchTerm]
  );

  if (!searchTerm) {
    return <Expander {...clusters} itemRenderer={Cluster} />;
  }

  if (searchResults.length > 0) {
    const clubs = searchResults.map(club => ({
      ...club,
    }));
    return <Cluster clubs={clubs} />;
  }

  return <Text>{noHits}</Text>;
};

const ChooseClub: NamedFC<Props> = ({
  clubs,
  clusters,
  geoLocationButtonText,
  layout,
  noHits,
  search,
  showAllCentersText,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchMode, setSearchMode] = useState<Mode>();
  const [filteredClubs, setFilteredClubs] = useState(clubs);
  const [isLoading, setIsLoading] = useState(false);

  const clearSearch = () => {
    setSearchMode(undefined);
    setFilteredClubs(clubs);
    setSearchTerm('');
  };

  const handleGeoSearch = () => {
    setSearchMode(Mode['GeoLocation']);

    setIsLoading(true); // NOTE: Finding the user's position sometimes takes a while
    setFilteredClubs([]);
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;

        const geoFilteredClubs = clubs
          .filter(club => {
            const distance = distanceBetween(
              { latitude, longitude },
              {
                latitude: club.geoLocation.latitude,
                longitude: club.geoLocation.longitude,
              }
            );

            return distance <= definitionOfNearby;
          })
          .sort(
            by(club =>
              distanceBetween(
                { latitude, longitude },
                {
                  latitude: club.geoLocation.latitude,
                  longitude: club.geoLocation.longitude,
                }
              )
            )
          );

        setFilteredClubs(() => geoFilteredClubs);
        setIsLoading(false);
      },
      () => {
        // NOTE: We should probably show a message, letting the user know that geolocation failed
        setFilteredClubs(clubs);
        setIsLoading(false);
      }
    );
  };

  return (
    <Layout {...layout}>
      <div className="choose-club">
        <div className="choose-club__search">
          {searchMode === Mode['GeoLocation'] ? null : (
            <Search
              {...search}
              theme={Search.themes.inline}
              value={searchTerm}
              onChangeFunc={e => {
                setSearchMode(Mode['Search']);
                setSearchTerm(e);
              }}
            />
          )}
          <div className="choose-club__geo-search">
            {searchMode === Mode['GeoLocation'] ? (
              <Button
                text={showAllCentersText}
                onClick={() => clearSearch()}
                variant={Button.variants.secondary}
                size={Button.sizes.small}
              />
            ) : (
              <Button
                leadingIcon={<Crosshairs />}
                onClick={() => {
                  handleGeoSearch();
                }}
                text={geoLocationButtonText}
                variant={Button.variants.secondary}
                size={Button.sizes.small}
              />
            )}
          </div>
        </div>
        {isLoading ? (
          <div className="choose-club__spinner">
            <Spinner size={Spinner.sizes.medium} />
          </div>
        ) : (
          <SearchArea
            clusters={clusters}
            clubs={filteredClubs}
            isGeoSearch={searchMode === Mode['GeoLocation']}
            searchTerm={searchTerm}
            noHits={noHits}
          />
        )}
      </div>
    </Layout>
  );
};

ChooseClub.displayName = 'ChooseClub';

export default ChooseClub;
