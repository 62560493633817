import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button/link-button';
import SvgElixia from '@sats-group/ui-lib/react/logos/elixia';
import Sats from '@sats-group/ui-lib/react/logos/sats';
import Text from '@sats-group/ui-lib/react/text/text';

import type { NamedFC } from 'shared-ui/named-fc.types';

import { GenericError as Props } from './generic-error.props';

const GenericError: NamedFC<Props> = ({
  language,
  link,
  outcome,
  step,
  text,
}) => {
  return (
    <div className="generic-error">
      <div className="generic-error__logo">
        {language === 'fi' ? <SvgElixia /> : <Sats />}
      </div>
      <Text elementName="h1" size={Text.sizes.large}>
        {step}
      </Text>
      <Text elementName="p">{text}</Text>
      {outcome ? (
        <Text elementName="p" size={Text.sizes.small}>
          {outcome}
        </Text>
      ) : null}
      {link ? (
        <LinkButton variant={LinkButton.variants.secondaryWhite} {...link} />
      ) : null}
    </div>
  );
};

GenericError.displayName = 'GenericError';

export default GenericError;
