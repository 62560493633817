import cn from 'classnames';
import React from 'react';

import ElixiaAnniversary from '@sats-group/ui-lib/react/logos/elixia-anniversary-light';
import SatsAnniversary from '@sats-group/ui-lib/react/logos/sats-anniversary-light';

import { Logo as Props } from './logo.props';

const Logo: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  language,
  className,
  href,
  label,
}) => (
  <a href={href} aria-label={label} className={cn(className)}>
    {language === 'fi' ? <ElixiaAnniversary /> : <SatsAnniversary />}
  </a>
);

export default Logo;
