import React from 'react';

import Link from '@sats-group/ui-lib/react/link';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import Logo from 'shared-ui/components/logo/logo';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { LeadConfirmation as Props } from './lead-confirmation.props';

const LeadConfirmation: NamedFC<Props> = ({
  description,
  link,
  linkButton,
  logo,
  title,
}) => {
  return (
    <div className="lead-confirmation">
      <Logo {...logo} />
      <Text elementName="h1" size={'headline1'} theme={'headline'} italic>
        {title}
      </Text>
      <Text elementName="p">{description}</Text>
      <LinkButton {...linkButton} />
      <Link {...link} />
    </div>
  );
};

LeadConfirmation.displayName = 'LeadConfirmation';

export default LeadConfirmation;
