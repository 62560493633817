import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button/link-button';
import Text from '@sats-group/ui-lib/react/text/text';

import Layout from 'shared-ui/components/layout/layout';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { EmployeeLogin as Props } from './employee-login.props';

const EmployeeLogin: NamedFC<Props> = ({ description, layout, link }) => {
  return (
    <Layout {...layout}>
      <div className="employee-login">
        <Text size={Text.sizes.large}>{description}</Text>
        <div className="employee-login__link">
          <LinkButton
            data-test-employee-login
            size={LinkButton.sizes.large}
            variant={LinkButton.variants.cta}
            {...link}
          />
        </div>
      </div>
    </Layout>
  );
};

EmployeeLogin.displayName = 'EmployeeLogin';

export default EmployeeLogin;
