import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import type { Disclaimer as Props } from './disclaimer.props';

const Disclaimer: React.FunctionComponent<Props> = ({ title, description }) => (
  <div className="disclaimers">
    <Text size={Text.sizes.interface} theme={Text.themes.emphasis}>
      {title}
    </Text>
    <Text size={Text.sizes.interface}>{description}</Text>
  </div>
);

export default Disclaimer;
