import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import Employee from 'shared-ui/components/employee/employee';
import EmployeeSearch from 'shared-ui/components/employee-search/employee-search';
import Layout from 'shared-ui/components/layout/layout';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { ChooseSalesPerson as Props } from './choose-sales-person.props';

const ChooseSalesPerson: NamedFC<Props> = ({
  description,
  employees,
  layout,
  skip,
  search,
}) => {
  return (
    <Layout {...layout}>
      <div className="choose-sales-person">
        <Text className="choose-sales-person__description" elementName="p">
          {description}
        </Text>

        <div>
          {employees.map(employee => (
            <Employee key={employee.name} {...employee} />
          ))}
        </div>
        <EmployeeSearch {...search} />
        <div className="choose-sales-person__skip">
          <LinkButton variant={LinkButton.variants.secondary} {...skip} wide />
        </div>
      </div>
    </Layout>
  );
};

ChooseSalesPerson.displayName = 'ChooseSalesPerson';

export default ChooseSalesPerson;
