import type { NamedFC } from "shared-ui/named-fc.types";
import GenericError from "shared-ui/pages/generic-error/generic-error.page";
import Register from "../functions/register/register.page";
import PaymentStatus from "../functions/payment-status/payment-status.page";
import Payment from "../functions/payment/payment.page";
import Parked from "../functions/parked/parked.page";
import MarketSelection from "../functions/market-selection/market-selection.page";
import Login from "../functions/login/login.page";
import LeadConfirmation from "../functions/lead-confirmation/lead-confirmation.page";
import Lead from "../functions/lead/lead.page";
import EmployeeLogin from "../functions/employee-login/employee-login.page";
import CustomizeMembership from "../functions/customize-membership/customize-membership.page";
import Confirmation from "../functions/confirmation/confirmation.page";
import ChooseSalesPerson from "../functions/choose-sales-person/choose-sales-person.page";
import ChooseMembership from "../functions/choose-membership/choose-membership.page";
import ChooseMemberType from "../functions/choose-member-type/choose-member-type.page";
import ChooseClub from "../functions/choose-club/choose-club.page";

/** This export is automatically generated. */
export const pages: Record<string, NamedFC> = {ChooseClub,ChooseMemberType,ChooseMembership,ChooseSalesPerson,Confirmation,CustomizeMembership,EmployeeLogin,GenericError,Lead,LeadConfirmation,Login,MarketSelection,Parked,Payment,PaymentStatus,Register,};
