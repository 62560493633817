import Info from '@sats-group/icons/24/info';
import cn from 'classnames';
import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Modal from '@sats-group/ui-lib/react/modal';
import Text from '@sats-group/ui-lib/react/text';

import RichText from 'shared-ui/components/rich-text/rich-text';

import { MemberTypeCard as Props } from './member-type-card.props';

const MemberTypeCard: React.FunctionComponent<Props> = ({
  description,
  highlighted,
  id,
  link,
  name,
  systemId,
  tooltip,
}) => {
  const memberTypeCard = cn('member-type-card', {
    'member-type-card--highlighted': highlighted,
  });

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div className={memberTypeCard} data-id={id} data-system-id={systemId}>
      <div className="member-type-card__descriptions">
        <div className="member-type-card__title">
          <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
            {name}
          </Text>
          {tooltip ? (
            <>
              <Button
                leadingIcon={<Info />}
                size={Button.sizes.small}
                variant={Button.variants.tertiary}
                onClick={() => setModalOpen(true)}
                {...tooltip.button}
              />
              {isModalOpen ? (
                <Modal onClose={() => setModalOpen(false)} {...tooltip.modal}>
                  <div className="member-type-card__tooltip-content">
                    <Text>{tooltip.text}</Text>
                  </div>
                </Modal>
              ) : null}
            </>
          ) : null}
        </div>
        {description ? <RichText {...description} /> : null}
      </div>
      <div className="member-type-card__link">
        <LinkButton
          {...link}
          variant={highlighted ? 'primary' : 'secondary'}
          wide={true}
          data-test-select-membertype={id}
        />
      </div>
    </div>
  );
};

export default MemberTypeCard;
